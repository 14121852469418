/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


@use "@material/tooltip/styles";


html
{
  overflow-x: hidden;
  overflow-y: hidden;

  user-select: none;
}


body
{
  margin: 0;
  padding: 0;

  --fierry-full-opacity:        1.00;
  --fierry-high-opacity:        0.90;
  --fierry-medium-opacity:      0.60;
  --fierry-disabled-opacity:    0.38;
  --fierry-divider-opacity:     0.12;
  --fierry-highligh-opacity:    0.08;

  --fierry-transition-duration: 300ms;
  --fierry-transition-function: cubic-bezier(0.4, 0.0, 0.2, 1);
}
