/*
 * Copyright (C) 2019 - present Marek Kuzora - All Rights Reserved.
 */


@use "@material/typography/mdc-typography";


html
{
  /* Headline 5 */
  --mdc-typography-headline5-font-size: 1.5rem;
  --mdc-typography-headline5-line-height: 2.5rem;
  --mdc-typography-headline5-letter-spacing: 0.2px;

  --mdc-typography-headline5-font-weight: 400;
  --mdc-typography-headline5-text-transform: none;
  --mdc-typography-headline5-text-decoration: none;

  /* Headline 6 */
  --mdc-typography-headline6-font-size: 1.25rem;
  --mdc-typography-headline6-line-height: 2.0rem;
  --mdc-typography-headline6-letter-spacing: 0.1px;

  --mdc-typography-headline6-font-weight: 400;
  --mdc-typography-headline6-text-transform: none;
  --mdc-typography-headline6-text-decoration: none;

  /* Subtitle 1 */
  --mdc-typography-subtitle1-font-size: 1rem;
  --mdc-typography-subtitle1-line-height: 1.75rem;
  --mdc-typography-subtitle1-letter-spacing: 0.5px;

  --mdc-typography-subtitle1-font-weight: 500;
  --mdc-typography-subtitle1-text-transform: none;
  --mdc-typography-subtitle1-text-decoration: none;

  /* Subtitle 2 */
  --mdc-typography-subtitle2-font-size: 0.875rem;
  --mdc-typography-subtitle2-line-height: 1.5rem;
  --mdc-typography-subtitle2-letter-spacing: 0.25px;

  --mdc-typography-subtitle2-font-weight: 500;
  --mdc-typography-subtitle2-text-transform: none;
  --mdc-typography-subtitle2-text-decoration: none;


  /* Body 1 */
  --mdc-typography-body1-font-size: 1.0rem;
  --mdc-typography-body1-line-height: 1.5rem;
  --mdc-typography-body1-letter-spacing: 0.5px;

  --mdc-typography-body1-font-weight: 400;
  --mdc-typography-body1-text-transform: none;
  --mdc-typography-body1-text-decoration: none;

  /* Body 2 */
  --mdc-typography-body2-font-size: 0.875rem;
  --mdc-typography-body2-line-height: 1.5rem;
  --mdc-typography-body2-letter-spacing: 0.25px;

  --mdc-typography-body2-font-weight: 400;
  --mdc-typography-body2-text-transform: none;
  --mdc-typography-body2-text-decoration: none;

  /* Overline */
  --mdc-typography-overline-font-size: 0.75rem;
  --mdc-typography-overline-line-height: 2.0rem;
  --mdc-typography-overline-letter-spacing: 2.0px;

  --mdc-typography-overline-font-weight: 500;
  --mdc-typography-overline-text-transform: uppercase;
  --mdc-typography-overline-text-decoration: none;

  /* Button */
  --mdc-typography-button-font-size: 0.875rem;
  --mdc-typography-button-line-height: 2.25rem;
  --mdc-typography-button-letter-spacing: 1.25px;

  --mdc-typography-button-font-weight: 500;
  --mdc-typography-button-text-transform: uppercase;
  --mdc-typography-button-text-decoration: none;
}
